/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import LocationInfo from "../LocationInfo/LocationInfo";
import StreamDescription from "../StreamDescription/StreamDescription";
import "./VideoView.css";
import SimilarVideosSidebar from "../SimilarVideosSidebar/SimilarVideosSidebar";
import ManualDescription from "../ManualDescription/ManualDescription"; // Import the ManualDescription component
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import Facts from "../Facts/Facts";

const VideoView = ({
  feature,
  setIsMiniPlayerActive,
  setMiniPlayerFeature,
  setMapState,
}) => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [localFeature, setLocalFeature] = useState(feature);
  const [placeQid, setPlaceQid] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [manualName, setManualName] = useState("Unknown Manual Name"); // Define manualName state
  const [manualDescription, setManualDescription] = useState(
    "No description available."
  ); // Define manualDescription state
  const [showCensoredOverlay, setShowCensoredOverlay] = useState(false);
  const [censoredWarning, setCensoredWarning] = useState("");
  const [allowedToPlay, setAllowedToPlay] = useState(false);


  const coordinates = localFeature?.geometry?.coordinates || null;
  const featureName = localFeature?.properties?.name || "Stream";
  const videoThumbnail = `https://img.youtube.com/vi/${localFeature?.properties?.videoId}/hqdefault.jpg`;


  useEffect(() => {
    const fetchFeature = async () => {
      
      try {
        let foundFeature = feature || null;
  
        if (!feature) {
          // 🔹 Fetch directly by videoId first
          const videoIdResponse = await fetch(
            `https://server.earf.tv/live-cameras/camera-by-video/${videoId}`,
            {
              method: "GET",
              headers: {
                "x-api-key": process.env.REACT_APP_EARF_API_KEY,
                "Content-Type": "application/json",
                "X-Request-Source": "Stream-View-By-VideoId",
              },
            }
          );
  
          if (videoIdResponse.ok) {
            const { camera } = await videoIdResponse.json();
            if (camera) {
              foundFeature = {
                properties: {
                  id: camera.id,
                  name: camera.name || "Unknown Name",
                  videoId: camera.video_id,
                  channelId: camera.channel_id,
                  channelName: camera.channel_name || "Unknown Channel",
                  channelThumbnail: camera.channel_thumbnail || null,
                  thumbnail: camera.thumbnail || null,
                  status: camera.status || "offline",
                  qid: camera.qid || null,
                  website: camera.website || null,
                  wikidata: camera.wikidata || null,
                  tag1: camera.tag1 || null,
                  tag2: camera.tag2 || null,
                  tag3: camera.tag3 || null,
                  useChannelForLiveCheck:
                    camera.use_channel_for_live_check || false,
                  title: camera.title || "Untitled",
                  partner: camera.partner || null,
                  manualname: camera.manualname || "Unknown Manual Name",
                  manualdescription: camera.manualdescription || "No description available.",
                  censoredwarning: camera.censoredwarning || "Viewer discretion is advised.",
                  censored: camera.censored || "no",
                },
                geometry:
                  camera.latitude && camera.longitude
                    ? {
                        type: "Point",
                        coordinates: [
                          parseFloat(camera.longitude),
                          parseFloat(camera.latitude),
                        ],
                      }
                    : null,
              };
  
              // 🔴 **STOP IMMEDIATELY AFTER FINDING CAMERA**
              console.log("✅ Found by Video ID. Stopping further requests.");
              setLocalFeature(foundFeature);
              if (foundFeature.properties.censored === "yes") {
                const storageKey = `acceptedCensor-${foundFeature.properties.videoId}`;
                const hasAccepted = localStorage.getItem(storageKey);
                if (!hasAccepted) {
                  setShowCensoredOverlay(true);
                  setCensoredWarning(
                    foundFeature.properties.censoredwarning || "Viewer discretion is advised."
                  );
                } else {
                  setAllowedToPlay(true);
                }
              } else {
                setAllowedToPlay(true); // No censor, allow play
              }
              
              
              setPlaceQid(foundFeature.properties.qid || "");
              setCountryCode(foundFeature.properties.country_code || "");
              setManualName(
                foundFeature.properties.manualname || "Unknown Manual Name"
              );
              setManualDescription(
                foundFeature.properties.manualdescription || "No description available."
              );
              return;
            }
          }
        }
  
        // 🔹 Try live cameras next (only if NOT found)
        if (!foundFeature) {
          const liveResponse = await fetch("https://server.earf.tv/live-cameras", {
            method: "POST",
            headers: {
              "x-api-key": process.env.REACT_APP_EARF_API_KEY,
              "Content-Type": "application/json",
              "X-Request-Source": "Stream-View-Live",
            },
          });
          const liveData = await liveResponse.json();
          foundFeature = liveData.features.find(
            (f) => f.properties.videoId === videoId
          );
  
          if (foundFeature) {
            console.log("✅ Found in Live Cameras. Stopping Browse request.");
            setLocalFeature(foundFeature);
            setPlaceQid(foundFeature.properties.qid || "");
            setCountryCode(foundFeature.properties.country_code || "");
            setManualName(
              foundFeature.properties.manualname || "Unknown Manual Name"
            );
            setManualDescription(
              foundFeature.properties.manualdescription || "No description available."
            );
            return;
          }
        }
  
        // 🔹 Try browse cameras last (only if NOT found)
        if (!foundFeature) {
          const browseResponse = await fetch(
            "https://server.earf.tv/browse-cameras",
            {
              method: "POST",
              headers: {
                "x-api-key": process.env.REACT_APP_EARF_API_KEY,
                "Content-Type": "application/json",
                "X-Request-Source": "Stream-View-Browse",
              },
            }
          );
          const browseData = await browseResponse.json();
          foundFeature = browseData.features.find(
            (f) => f.properties.videoId === videoId
          );
  
          if (foundFeature) {
            setLocalFeature(foundFeature);
            setPlaceQid(foundFeature.properties.qid || "");
            setCountryCode(foundFeature.properties.country_code || "");
            setManualName(
              foundFeature.properties.manualname || "Unknown Manual Name"
            );
            setManualDescription(
              foundFeature.properties.manualdescription || "No description available."
            );
          } else {
            navigate("/404");
          }
        }
      } catch (error) {
        console.error("Error fetching feature:", error);
        navigate("/404");
      } finally {
        setIsLoading(false);
      }
    };
  
    if (!isMobile) {
      setIsMiniPlayerActive(false);
      setMiniPlayerFeature(null);
    }
  
    fetchFeature();
  }, [
    feature,
    videoId,
    isMobile,
    setIsMiniPlayerActive,
    setMiniPlayerFeature,
    navigate,
  ]);
  
  const handleAcceptCensored = () => {
    localStorage.setItem(
      `acceptedCensor-${localFeature.properties.videoId}`,
      "true"
    );

    setShowCensoredOverlay(false);
    setAllowedToPlay(true); // Now play allowed

    if (!featureName || featureName === "Stream") return;

    ReactGA.event({
      category: "Video",
      action: "Play",
      label: featureName,
    });
    
  };
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  
  useEffect(() => {
    if (!featureName || featureName === "Stream") return;
  
    const startTime = Date.now();
  
    const intervalTimer = setInterval(() => {
      const minutesWatched = Math.floor((Date.now() - startTime) / 60000);
  
      ReactGA.event({
        category: "Video",
        action: `Watched ${minutesWatched} minute${minutesWatched !== 1 ? "s" : ""}`,
        label: featureName,
      });
    }, 60000); // Every 60 seconds
  
    return () => clearInterval(intervalTimer);
  }, [featureName]);
  
  
  const handleTagClick = (tag) => {
    navigate(`/browse/${encodeURIComponent(tag)}`);
  };
  // Function to navigate to the map with coordinates
  const handleFeatureClick = (latitude, longitude, zoom = 15) => {
    if (isNaN(latitude) || isNaN(longitude)) {
      console.error("Invalid coordinates:", latitude, longitude);
      return;
    }

    if (isNaN(zoom)) {
      console.warn("Invalid zoom level, defaulting to 15.");
      zoom = 15;
    }

    navigate(`/explore?lat=${latitude}&lng=${longitude}&zoom=${zoom}`);
  };

  useEffect(() => {
    // Run when isLoading changes
    if (!isLoading) {
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 1000); // 1 second delay

      return () => clearTimeout(timer); // Cleanup function
    } else {
      setShowLoader(true); // Ensure loader is shown when loading starts
    }
  }, [isLoading]); // Depend only on isLoading

  if (showLoader) {
    return (
      <div className="full-container">
        <div className="spinner-wrapper">
          <img
            className="youtube-spinner"
            src="/Stream-Assets/youtube-long.png"
            alt="Loading"
          />
        </div>

        {/* Footer */}
        <div className="footer-loader">
          <span className="from-text">from</span>
          <div className="footer-content">
            <img className="earf-logo" src="/Earf-E.png" alt="Earf" />
            <h3 className="earf-text">EARF</h3>
          </div>
        </div>
      </div>
    );
  }


  return (
    <>
      {localFeature && (
        <>
<Helmet prioritizeSeoTags>
  <title>{localFeature ? `${featureName} | Earf` : 'Live Stream | Earf'}</title>
  <meta
    name="description"
    content={
      localFeature
        ? `Watch the live stream of ${featureName}, explore breathtaking locations in ${localFeature?.properties?.wikidata || "this location"}, and discover more incredible live streams from around the world on Earf.`
        : 'Watch live streams from around the world on Earf — Earth’s real-time window into nature, cities, and global events.'
    }
  />
  <link
    rel="canonical"
    href={
      localFeature
        ? `https://earf.tv/browse/${encodeURIComponent(
            (localFeature.properties?.tag2 || "unknown").toLowerCase().replace(/\s+/g, "-")
          )}/stream/${localFeature.properties?.videoId}`
        : "https://earf.tv"
    }
  />

  <meta
    property="og:title"
    content={localFeature ? `${featureName} Stream | Earf` : 'Live Stream | Earf'}
  />
  <meta
    property="og:description"
    content={
      localFeature
        ? `Watch the live stream of ${featureName}, explore breathtaking locations in ${localFeature?.properties?.wikidata || "this location"}, and discover more incredible live streams from around the world on Earf.`
        : 'Watch real-time live streams from cities, nature, and events around the world on Earf.'
    }
  />
  <meta
    property="og:image"
    content={
      localFeature
        ? `https://img.youtube.com/vi/${localFeature.properties?.videoId}/hqdefault.jpg`
        : '/earfyeezy.webp'
    }
  />
  <meta
    property="og:url"
    content={
      localFeature
        ? `https://earf.tv/browse/${encodeURIComponent(
            (localFeature.properties?.tag2 || "unknown").toLowerCase().replace(/\s+/g, "-")
          )}/stream/${localFeature.properties?.videoId}`
        : 'https://earf.tv'
    }
  />
  <meta property="og:type" content="video.other" />
  <meta
    property="og:video"
    content={
      localFeature
        ? `https://www.youtube.com/embed/${localFeature.properties?.videoId}/hqdefault.jpg`
        : ''
    }
  />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content={featureName ? `${featureName} Stream | Earf` : 'Live Stream | Earf'} />
  <meta name="twitter:description" content="Watch global livestreams and explore Earth's beauty in real time on Earf." />
  <meta
    name="twitter:image"
    content={
      localFeature
        ? `https://img.youtube.com/vi/${localFeature.properties?.videoId}/hqdefault.jpg`
        : '/earfyeezy.webp'
    }
  />
</Helmet>
        </>
      )}

      <div className="video-view">
        <div className="main-video-content">
        <div className="video-container" style={{ position: "relative" }}>
  {allowedToPlay ? (
    <iframe
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  ) : (
    <>
      <img
        src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
        alt="Stream Thumbnail"
        className={`video-thumbnail blurred-thumbnail`}
      />
      {showCensoredOverlay && (
  <div className="censored-overlay">
  <div className="censored-warning-head">Content Discretion Warning</div>
    <div className="censored-warning-text">{censoredWarning}</div>
    <button className="accept-button" onClick={handleAcceptCensored}>
      Accept & Continue
    </button>
  </div>
)}

    </>
  )}
</div>


          <div className="video-info">
  {localFeature && (
    <div className="info-container">
      <div className="info-left">
        <div className="channel-info">
          {localFeature.properties.channelThumbnail ? (
            <img
              id="channel-thumbnail"
              src={localFeature.properties.channelThumbnail}
              alt={localFeature.properties.channelName || "Channel Thumbnail"}
            />
          ) : (
            <div
              className="skeleton channel-thumbnail-skeleton"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
              }}
            ></div>
          )}

          <div className="channel-details">
            <h2 className="feature-title">
              {featureName ? (
                featureName
              ) : (
                <span
                  className="skeleton"
                  style={{
                    width: "70%",
                    display: "inline-block",
                  }}
                >
                  &nbsp;
                </span>
              )}
            </h2>

            <h4 id="channel-name">
              {localFeature.properties.channelName ? (
                <a
                  href={`https://www.youtube.com/channel/${localFeature.properties.channelId}`}
                  target="_blank"
                  rel="noopener"
                >
                  {localFeature.properties.channelName}{" "}
                  {localFeature.properties.partner === "YES" && (
                    <img
                      src="/partner.png"
                      alt="Partner"
                      aria-label="Partner"
                      title="Verified Partner"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "3px",
                      }}
                    />
                  )}
                </a>
              ) : (
                <span
                  className="skeleton"
                  style={{
                    width: "120px",
                    display: "inline-block",
                  }}
                >
                  &nbsp;
                </span>
              )}
            </h4>

            <p
              style={{
                color: localFeature?.properties?.website ? "inherit" : "grey",
              }}
            >
              {localFeature?.properties?.website ? (
                <a
                  href={
                    localFeature.properties.website.startsWith("http://") ||
                    localFeature.properties.website.startsWith("https://")
                      ? localFeature.properties.website
                      : `https://${localFeature.properties.website}`
                  }
                  target="_blank"
                  rel="noopener"
                  className="website-link"
                >
                  Official Website
                </a>
              ) : (
                "No Website Available"
              )}
            </p>

            <p className="wikidataText">
              {localFeature?.properties?.wikidata ? (
                localFeature.properties.wikidata
              ) : (
                <span
                  className="skeleton"
                  style={{
                    width: "80px",
                    display: "inline-block",
                  }}
                >
                  &nbsp;
                </span>
              )}
            </p>

            <div className="feature-tags">
              {[
                localFeature?.properties?.tag1,
                localFeature?.properties?.tag2,
              ].filter((tag) => tag).length > 0 ? (
                [localFeature.properties.tag1, localFeature.properties.tag2]
                  .filter((tag) => tag)
                  .map((tag, index) => (
                    <span
                      key={index}
                      className="feature-tag"
                      onClick={index === 2 ? null : () => handleTagClick(tag)} // Make the third tag unclickable
                    >
                      {tag}
                    </span>
                  ))
              ) : (
                <>
                  <span
                    className="skeleton feature-tag"
                    style={{
                      width: "50px",
                      display: "inline-block",
                    }}
                  >
                    &nbsp;
                  </span>
                  <span
                    className="skeleton feature-tag"
                    style={{
                      width: "70px",
                      display: "inline-block",
                    }}
                  >
                    &nbsp;
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

                <div className="info-right">
                  {coordinates && (
                    <div className="location-info-container">
                      {/* LocationInfo component */}
                      <LocationInfo coordinates={coordinates} />

                      {/* Button placed under LocationInfo */}
                      <div className="navigate-to-map-container">
                        <button
                          onClick={() =>
                            handleFeatureClick(coordinates[1], coordinates[0])
                          } // Passing latitude and longitude
                          className="navigate-to-map-button"
                        >
                          <i className="fas fa-map-marker-alt"></i> View on Map
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {manualName !== "Unknown Manual Name" &&
            manualDescription !== "No description available." && (
              <ManualDescription
                manualName={manualName}
                manualDescription={manualDescription}
              />
            )}

          <StreamDescription qid={placeQid} countryCode={countryCode} />
        </div>

        <SimilarVideosSidebar
          wikidata={localFeature?.properties?.wikidata}
          tags={[
            localFeature?.properties?.tag1,
            localFeature?.properties?.tag2,
            localFeature?.properties?.tag3,
          ].filter(Boolean)}
          currentVideoId={localFeature?.properties?.videoId}
        />
      </div>
    </>
  );
};

export default VideoView;
