import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styles from "./CountryDetails.module.css";

const CountryDetails = () => {
  const { countryName } = useParams();
  const [countryData, setCountryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountryDetails = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const API_KEY = process.env.REACT_APP_EARF_API_KEY;
        const res = await fetch(`https://server.earf.tv/country-profiles/${countryName}`, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
          },
        });

        if (!res.ok) throw new Error("Failed to fetch");

        const json = await res.json();
        setCountryData(json.properties);
      } catch (err) {
        setError("Failed to load country data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountryDetails();
  }, [countryName]);

  if (isLoading) {
    return (
      <div className="full-container">
        <div className="spinner-wrapper">
          <div className="spinner-content">
            <img
              className="image-spinner"
              src="/metaimage.png"
              alt="Loading"
            />
            <div className="browse-loading-text">
              Learning about <span className="earf-highlight">{countryName}</span>...
            </div>
          </div>
        </div>
  
        {/* Loader Footer */}
        <footer className="loader-footer">
          <div className="footer-content">
            <p>
              Is your stream on{" "}
              <Link to="/camera-request" className="earf-highlight">
                EARF
              </Link>
              ? Want to get verified?{" "}
              <img
                src="/partner.png"
                alt="Verified Partner"
                aria-label="Verified Partner"
                className="tick-icon"
              />{" "}
              <Link to="/how-to-get-verified" className="loading-footer-link">
                Learn How
              </Link>
            </p>
          </div>
        </footer>
      </div>
    );
  }
  

  if (error || !countryData) {
    return <div className={styles.error}>{error}</div>;
  }

  const {
    name,
    iso_code,
    common_name,
    official_name,
    native_name,
    capital,
    region,
    subregion,
    description,
    population,
    area,
    landlocked,
    motto,
    timezones,
    dialing_root,
    dialing_suffixes,
    currencies,
    postal_format,
    emergency_numbers,
    links,
    flags_png,
    coat_of_arms_png,
    demonyms,
  } = countryData;

  const nativeName = native_name?.eng?.common || "N/A";
  const currencyName = currencies ? Object.values(currencies)[0]?.name : "N/A";
  const currencySymbol = currencies ? Object.values(currencies)[0]?.symbol : "";
  const { m: male, f: female } = demonyms || {};
  const flagUrl = flags_png || `https://flagcdn.com/w320/${iso_code?.toLowerCase()}.png`;

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{name} | Earf</title>
        <meta name="description" content={`Explore ${name} on Earf — learn about its geography, stats, and emergency info.`} />
        <link rel="canonical" href={`https://earf.tv/country/${countryName}`} />
        <meta property="og:image" content={flagUrl} />
        <meta property="og:title" content={`${name} | Earf`} />
      </Helmet>

      <div className={styles.leftPanel}>
        <img src={flagUrl} alt={`${name} Flag`} className={styles.flag} />
        {coat_of_arms_png && (
          <img src={coat_of_arms_png} alt="Coat of Arms" className={styles.coat} />
        )}
        <div className={styles.statsBox}>
          <h3>Quick Facts</h3>
          <p><strong>Capital:</strong> {capital}</p>
          <p><strong>Region:</strong> {region}</p>
          <p><strong>Population:</strong> {population?.toLocaleString()}</p>
          <p><strong>Area:</strong> {area} km²</p>
          <p><strong>Timezone:</strong> {timezones?.join(", ")}</p>
          <p><strong>Currency:</strong> {currencyName} ({currencySymbol})</p>
          <p><strong>Landlocked:</strong> {landlocked ? "Yes" : "No"}</p>
        </div>
      </div>

      <div className={styles.rightPanel}>
        <h1 className={styles.title}>{name}</h1>
        <p className={styles.motto}><em>{motto || "No national motto available"}</em></p>

        <section className={styles.section}>
          <h2>Overview</h2>
          <p><strong>Official Name:</strong> {official_name}</p>
          <p><strong>Common Name:</strong> {common_name}</p>
          <p><strong>Native Name:</strong> {nativeName}</p>
          <p><strong>Subregion:</strong> {subregion}</p>
          <p className={styles.description}>{description}</p>
        </section>

        <section className={styles.section}>
          <h2>Emergency Numbers</h2>
          {emergency_numbers ? (
            <ul>
              {emergency_numbers.map((num, i) => {
                const [key, value] = num.replace(/["{}]/g, "").split(": ");
                return (
                  <li key={i}>
                    <strong>{key.replace(/_/g, " ").replace(/\b\w/g, c => c.toUpperCase())}:</strong> {value}
                  </li>
                );
              })}
            </ul>
          ) : <p>N/A</p>}
        </section>

        <section className={styles.section}>
          <h2>Contact & Postal</h2>
          <p><strong>Dialing Code:</strong> +{dialing_root} {dialing_suffixes?.join(", ")}</p>
          <p><strong>Postal Format:</strong> {postal_format || "N/A"}</p>
        </section>

        <section className={styles.section}>
          <h2>Demonyms</h2>
          <p><strong>Male:</strong> {male || "N/A"}</p>
          <p><strong>Female:</strong> {female || "N/A"}</p>
        </section>

        <section className={styles.section}>
          <h2>External Links</h2>
          <ul>
            {links && Object.entries(links).length > 0 ? (
              Object.entries(links).map(([label, url], i) => (
                <li key={i}><a href={url} target="_blank" rel="noopener noreferrer">{label}</a></li>
              ))
            ) : (
              <li>No links available</li>
            )}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default CountryDetails;
